import React from 'react';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';

import AnchorLink from '../components/AnchorLink';
import Bio from '../components/Bio';


export default class AboutPage extends React.Component {
    render() {
        const siteMetadata = this.props.data.site.siteMetadata;

        return (
            <>
                <Helmet title={`Legal notice | ${siteMetadata.title}`}>
                    <html lang="en"/>
                    <link rel="canonical" href={`${siteMetadata.siteUrl}legal`} />
                    <meta name="description" value={siteMetadata.description} />
                    <meta name="author" value={siteMetadata.author.fullName} />
                    <meta property="og:type" content="website" />
                    <meta property="og:title" content="Legal notice" />
                    <meta property="og:description" content={`Terms and conditions for ${siteMetadata.title}`} />
                    <meta property="twitter:site:id" content={siteMetadata.twitterId} />
                </Helmet>
                <main>
                    <article className="content">
                        <h1>Legal</h1>
                        <h2>Terms and conditions</h2>
                        <p>All content provided on this website is for informational purposes only.</p>
                        <p>The owner of this website makes no representations as to the accuracy
                        or completeness of any information on this site or found by following
                        any link on this site. The owner will not be liable for any errors or
                        omissions in this information nor for the availability of this
                        information. The owner will not be liable for any losses, injuries, or
                        damages from the display or use of this information.</p>
                        <p>These terms and conditions of use are subject to change at any time and without notice.</p>
                        <h2>Privacy policy</h2>
                        <p>We <strong>do not</strong> use cookies or any other tracking tool.</p>
                        <p>We collect basic technical logs for diagnostic purposes and content
                        performance analysis. Those logs include visitor's IP address,
                        browser type and version, and visited page URL.</p>
                        <p>We <strong>do not</strong> share any of that information with
                        third-parties.</p>
                        <p>This privacy policy is subject to change at any time and without notice.</p>
                        <h2>Contact</h2>
                        <ul>
                            <li><p>This website is edited by: Julien Hartmann&nbsp;
                                   <a href="mailto:contact@etherdream.org">[contact]</a>
                            </p></li>
                            <li><p>This website is hosted by ONLINE SAS:</p>
                                <blockquote><address className="hosting vcard">
                                    <p className="adr">
                                        <a className="fn org url" href="https://www.online.net/">ONLINE SAS</a>
                                        &nbsp;-&nbsp;
                                        <span className="post-office-box">BP 438</span><br/>
                                        <span className="postal-code">75366</span>&nbsp;
                                        <span className="locality">Paris</span>&nbsp;
                                        <span className="post-office-box">CEDEX 08</span>&nbsp;
                                        <span className="country-name">FRANCE</span>
                                    </p>
                                    <strong>RCS: </strong> Paris B 433 115 904<br />
                                    <strong>Phone: </strong><a href="tel:+33184130000" className="tel">+33 1 84 13 00 00</a>
                                </address></blockquote>
                            </li>
                        </ul>
                    </article>
                    <AnchorLink className="back-to-top" href="#">back to top</AnchorLink>
                </main>
                <aside>
                    <Bio person={siteMetadata.author} />
                </aside>
            </>
        )
    }
}

export const pageQuery = graphql`
  query LegalMetadata {
    site {
      siteMetadata {
        title
        siteUrl
        description
        twitterId
        author { ...BioPerson }
      }
    }
  }
`
